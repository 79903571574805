/**
 * Implement Gatsby's Browser APIs in this file.
 *
 * See: https://www.gatsbyjs.org/docs/browser-apis/
 */

import "./src/style/style.css";
import "./src/style/carousel.css";
import "./src/style/present.css";

export const onClientEntry = () => {
  window["initScribitWidget"] = function () {
    window["scribitWidget"] = new window.scribit.widget(
      process.env.GATSBY_SCRIBIT_KEY,
      { wrapper: ".wp-block-embed__wrapper" }
    );
  };
};
